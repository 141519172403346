
<template>
  <div class="myFollow">
    <!-- 搜索 -->
    <van-search
      v-model="searchValue"
      show-action
      placeholder="搜索客户姓名或联系电话"
      maxlength="11"
      @clear="onSearch"
      @search="onSearch"
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <van-tabs v-model="active" @change="handleChangeTab" swipeable>
      <van-tab title="未联系" name="0">
        <p v-if="myFollowList.length === 0">暂无数据...</p>
        <van-list
          v-else
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :offset="10"
        >
          <div v-for="(follow, index) in myFollowList" :key="index">
            <van-row>
              <van-col span="24" style="padding: 15px 0 10px 23px">
                ID：
                <span>{{ follow.number }}</span>
              </van-col>
              <van-divider />
              <van-col span="12">
                客户姓名：
                <span>{{ follow.name }}</span>
              </van-col>
              <van-col span="12">
                客户电话：
                <span>{{ follow.mobile }}</span>
              </van-col>
              <van-col span="24">
                客户渠道：
                <span>{{ follow.place }}</span>
              </van-col>
              <van-divider />
              <div v-if="follow.contactlogs">
                <van-col span="24">
                  最近跟进日期：
                  <span>{{ follow.contactlogs.createtime }}</span>
                </van-col>
                <van-col span="24">
                  下次跟进日期：
                  <span>{{ follow.contactlogs.time }}</span>
                </van-col>
                <van-col span="24" style="padding-left: 42px">
                  跟进人员：
                  <span>{{ follow.contactlogs.name }}</span>
                </van-col>
                <van-col span="24" style="padding-left: 42px">
                  跟进结果：
                  <span>{{ follow.contactlogs.desc }}</span>
                </van-col>
              </div>
            </van-row>
            <van-row class="client-botton-item">
              <van-col span="24" @click="callPhone(follow.mobile)">
                <img src="../../../assets/client_phone.png" alt />拨打电话
              </van-col>
              <van-col
                span="12"
                v-for="(item, index) in noContactedText"
                :key="index"
              >
                <van-button
                  round
                  size="small"
                  class="client-botton"
                  type="primary"
                  @click="getFollowInfo(item, follow.id, follow.state)"
                  >{{ item }}</van-button
                >
              </van-col>
            </van-row>
          </div>
        </van-list>
      </van-tab>
      <van-tab title="已联系" name="1">
        <p v-if="myFollowList.length === 0">暂无数据...</p>
        <van-list
          v-else
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div v-for="(follow, index) in myFollowList" :key="index">
            <van-row>
              <van-col span="24" style="padding: 15px 0 10px 23px">
                ID：
                <span>{{ follow.number }}</span>
                <span class="client-right">
                  跟进次数：
                  <span id="count-text">{{ follow.count }}</span>
                </span>
              </van-col>
              <van-divider />
              <van-col span="12">
                客户姓名：
                <span>{{ follow.name }}</span>
              </van-col>
              <van-col span="12">
                客户电话：
                <span>{{ follow.mobile }}</span>
              </van-col>
              <van-col span="24">
                客户渠道：
                <span>{{ follow.place }}</span>
              </van-col>
              <van-divider />
              <div v-if="follow.contactlogs">
                <van-col span="24">
                  最近跟进日期：
                  <span>{{ follow.contactlogs.createtime }}</span>
                </van-col>
                <van-col span="24">
                  下次跟进日期：
                  <span>{{ follow.contactlogs.time }}</span>
                </van-col>
                <van-col span="24" style="padding-left: 42px">
                  跟进人员：
                  <span>{{ follow.contactlogs.name }}</span>
                </van-col>
                <van-col span="24" style="padding-left: 42px">
                  跟进结果：
                  <span>{{ follow.contactlogs.desc }}</span>
                </van-col>
              </div>
            </van-row>
            <van-row class="client-botton-item">
              <van-col span="24" @click="callPhone(follow.mobile)">
                <img src="../../../assets/client_phone.png" alt />拨打电话
              </van-col>
              <van-col
                span="12"
                v-for="(item, index) in contactedText"
                :key="index"
              >
                <van-button
                  round
                  size="small"
                  class="client-botton"
                  type="primary"
                  @click="getFollowInfo(item, follow.id, follow.state)"
                  >{{ item }}</van-button
                >
              </van-col>
            </van-row>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
    <!-- 下次跟进时间 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        title="选择年月日时分"
        :min-date="minDate"
        @confirm="onConfirmPicker"
        @cancel="showPicker = false"
      />
    </van-popup>

    <!-- 求助管理员 -->
    <van-popup v-model="showforHelpAdmin" closeable @close="closeforHelpAdmin">
      <div class="follow_header">
        <div></div>
        <span>求助管理员</span>
      </div>
      <van-form @submit="onSubmitForHelpAdmin" :show-error="false">
        <div v-for="(item, index) in forHelpResonList" :key="index">
          <van-button block type="default">
            <!-- 
            @click.prevent="getForHelpReson(index)" -->
            <img
              src="../../../assets/gou.png"
              alt
              style="width: 14px; height: 14px; margin-right: 5px"
            />
            {{ item }}
          </van-button>
        </div>
        <van-field
          v-model="forHelpResons"
          rows="3"
          autosize
          type="textarea"
          maxlength="30"
          placeholder="请填写求助理由："
          show-word-limit
          :rules="[{ required: true, message: '请填写求助理由' }]"
        />
        <div style="margin: 16px; margin-top: 20px">
          <van-button
            block
            type="info"
            native-type="submit"
            loading-text="确认"
            :loading="btn_loading"
            >确认</van-button
          >
        </div>
      </van-form>
    </van-popup>

    <!-- 跟进记录 -->
    <van-popup
      v-model="showFollowRecord"
      closeable
      id="followRecord"
      position="bottom"
      round
    >
      <div class="follow_header">
        <div></div>
        <span>跟进记录</span>
      </div>
      <!-- 跟进记录 -->
      <div class="follow-record-list">
        <div
          class="follow-record-item"
          v-for="(item, index) in fllowUpRecords"
          :key="index"
        >
          <div class="follow-record-count">{{ index + 1 }}</div>
          <van-row>
            <van-col span="24">
              下次跟进日期：
              <span>{{ item.time }}</span>
            </van-col>
            <van-col span="24">
              本次跟进日期：
              <span>{{ item.createtime }}</span>
            </van-col>
            <van-col span="24" style="padding-left: 23px">
              跟进结果：
              <span>{{ item.desc }}</span>
            </van-col>
            <van-col span="24" style="padding-left: 23px">
              跟进人员：
              <span>{{ item.name }}</span>
            </van-col>
            <van-col
              span="24"
              style="padding-left: 23px"
              v-if="item.remarkdesc"
            >
              跟进备注：
              <span>{{ item.remarkdesc }}</span>
            </van-col>
          </van-row>
          <van-divider />
        </div>
        <div style="margin: 16px; margin-top: 40px">
          <van-button block type="info" @click="callPhone(fllowUpRecordMobile)"
            >现在跟进</van-button
          >
        </div>
      </div>
    </van-popup>

    <!-- 客户参观记录 -->
    <van-popup v-model="showAttendRecord" closeable @close="closeAttendRecord">
      <div class="follow_header">
        <div></div>
        <span>客户参观记录</span>
      </div>
      <van-form
        @submit="onSubmitAttendRecord"
        label-width="80px"
        :show-error="false"
        style="height: 220px"
      >
        <van-field
          readonly
          clickable
          name="picker"
          :value="attendRecordForm.pepole"
          label="跟进人员"
          placeholder="请选择跟进人"
          @click="showPerson = true"
          :rules="[{ required: true, message: '请选择跟进人' }]"
          clearable
          required
        />
        <van-field
          readonly
          clickable
          name="calendar"
          :value="attendRecordForm.visitDate"
          label="参观时间"
          placeholder="请选择参观时间"
          required
          :rules="[{ required: true, message: '请选择参观时间' }]"
          @click="showVisitDatePicker = true"
        />
        <div style="margin: 16px; margin-top: 40px">
          <van-button
            block
            type="info"
            native-type="submit"
            loading-text="确认"
            :loading="btn_loading"
            >确认</van-button
          >
        </div>
      </van-form>
    </van-popup>
    <!-- 跟进人员列表 -->
    <van-popup v-model="showPerson" position="bottom">
      <van-picker
        show-toolbar
        :columns="personList"
        @confirm="onPersonConfirm"
        @cancel="showPerson = false"
      />
    </van-popup>
    <!-- 参观时间 -->
    <van-popup v-model="showVisitDatePicker" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        title="选择年月日时分"
        :min-date="minDate"
        @confirm="onConfirmVisitDatePicker"
        @cancel="showVisitDatePicker = false"
      />
    </van-popup>

    <!-- 全局加载 -->
    <van-overlay :show="isShow">
      <van-loading type="spinner" color="#CC9756" />
    </van-overlay>
  </div>
</template>

<script>
import {
  // 跟进列表
  getMyClientList,
  // 下拉选择框列表
  getSelectOptionsList,
  // 跟进人员列表
  getAdminAndFollowList,
  // 跟进信息
  contactlogs,
  // 预约入园
  orderconfirms,
  // 客户参观记录
  ordervisitlogs,
  // 跟进记录
  getFllowUpRecords,
  // 求助管理员
  updateCustomer,
} from "@/api/api";
import moment from "moment/moment";
export default {
  data() {
    return {
      // 按钮加载
      btn_loading: false,
      // 全局加载
      isShow: true,
      // 搜索值
      searchValue: "",
      // tab选择
      active: 0,
      // tab方式
      tabType: 1,
      // 上拉加载
      loading: false,
      finished: false,
      page: 1, //请求第几页
      pageSize: 10, //每页请求的数量
      total: 4, //总共的数据条数
      // 客户ID
      customerId: "",
      // 未联系button文本
      noContactedText: ["跟进信息", "预约入园信息", "求助管理员", "跟进记录"],
      // 已联系button文本
      contactedText: [
        "跟进信息",
        "跟进记录",
        "预约入园信息",
        "客户参观记录",
        "求助管理员",
      ],
      // 跟进列表信息
      myFollowList: [],
      // 时间列表显示与隐藏
      showPicker: false,
      minDate: new Date(),
      currentDate: moment(new Date()).format("YYYY-MM-DD HH:mm"),
      // 求助管理员弹框的显示与隐藏
      showforHelpAdmin: false,
      // 求助管理员求助原因
      forHelpReson: "修改客户信息",
      // 求助理由
      forHelpResons: "",
      // 求助按钮文本
      forHelpResonList: ["修改客户信息"], //"修改客户信息",
      // 求助原因选择
      forHelpResonIndex: 1,
      // 跟进记录弹框的显示与隐藏
      showFollowRecord: false,
      // 跟进记录信息
      fllowUpRecords: [],
      // 跟进记录电话
      fllowUpRecordMobile: "",
      // 客户参观记录弹框的显示与隐藏
      showAttendRecord: false,
      attendRecordForm: {
        pepole: "",
        visitDate: "",
      },
      // 跟进人控件显示与隐藏
      showPerson: false,
      // 跟进人员列表
      personList: [],
      followPersonList: [],
      // 参观时间控件得显示与隐藏
      showVisitDatePicker: false,
    };
  },
  created() {
    // 获取客户列表
    this.getMyFollowList(this.tabType);
    // 下拉列表选择
    this.getSelectOptionsList();
  },
  methods: {
    // tab切换选中值
    handleChangeTab() {
      this.page = 1;
      this.loading = false;
      this.finished = false;
      this.myFollowList = [];
      if (this.active === "0") {
        this.tabType = 1;
        this.getMyFollowList(this.tabType);
      } else {
        this.tabType = 2;
        this.getMyFollowList(this.tabType);
      }
    },
    // 获取我的跟进列表
    getMyFollowList(type) {
      getMyClientList({
        type: type,
        search: this.searchValue,
        page: this.page,
      })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.length === 0) {
              this.isShow = false;
            }
            // 加载状态结束
            this.loading = false;
            if (this.myFollowList.length >= res.meta.total) {
              this.finished = true;
              return;
            } else {
              this.myFollowList = this.myFollowList.concat(res.data);
              this.page++;
            }
            this.isShow = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 搜索
    onSearch() {
      this.page = 1;
      this.loading = false;
      this.finished = false;
      this.myFollowList = [];
      this.getMyFollowList(this.tabType);
    },
    // 加载更多
    onLoad() {
      this.getMyFollowList(this.tabType);
    },
    // 拨打电话
    callPhone(phone) {
      window.location.href = "tel:" + phone;
    },
    // 下拉列表选择
    getSelectOptionsList() {
      getSelectOptionsList().then((res) => {
        if (res.code === 200) {
          this.appointElderAgeList = res.data.ages;
          this.appointHealthList = res.data.healthconditions;
          this.reservationNumberList = res.data.numbers;
        }
      });
    },
    // 获取跟进人员列表
    getFollowList() {
      this.personList = [];
      this.followPersonList = [];
      getAdminAndFollowList().then((res) => {
        if (res.code === 200) {
          res.data.map((item) => {
            this.personList.push(item.name);
            this.followPersonList.push({ id: item.id, name: item.name });
          });
        }
      });
    },
    // 点击按钮
    getFollowInfo(text, id, state) {
      this.customerId = id;
      if (text === "跟进信息") {
        this.myFollowList.map((item) => {
          if (id === item.id) {
            this.$router.push({
              path: "/followInfo",
              query: {
                username: item.name,
                phone: item.mobile,
                id: id,
                follow: "1",
              },
            });
          }
        });
      } else if (text === "预约入园信息") {
        this.$router.push({
          path: "/appointGarden",
          query: { id: id, follow: "1" },
        });
      } else if (text === "求助管理员") {
        this.showforHelpAdmin = true;
      } else if (text === "跟进记录") {
        this.myFollowList.map((item) => {
          if (id === item.id) {
            this.fllowUpRecordMobile = item.mobile;
          }
        });
        // 跟进记录
        getFllowUpRecords({ id: this.customerId }).then((res) => {
          if (res.code === 200) {
            this.fllowUpRecords = res.data;
            if (this.fllowUpRecords.length === 0) {
              this.$toast("暂无跟进记录");
            } else {
              this.showFollowRecord = true;
            }
          }
        });
      } else if (text === "客户参观记录") {
        if (state === 3 || state === 4 || state === 6) {
          this.showAttendRecord = true;
          // 获取跟进人员列表
          this.getFollowList();
        } else {
          this.$toast("该客户还没有预约，添加失败！");
        }
      }
    },
    // 获取不能入住原因
    getStayinReson(index) {
      this.stayinReson.map((item, key) => {
        if (index === key) {
          this.followInfoData.reson = item.name;
          this.followResonIndex = key;
        }
      });
    },
    // 记录客户信息下次跟进时间选择
    onConfirmPicker(time) {
      this.followInfoData.date = moment(time).format("YYYY-MM-DD HH:mm:ss");
      this.showPicker = false;
    },
    // 关闭求助管理员弹窗清空表单数据
    closeforHelpAdmin() {
      this.forHelpResons = this.$options.data().forHelpResons;
    },
    // 求助原因选择
    // getForHelpReson(index) {
    //   this.forHelpResonList.map((item, key) => {
    //     if (index === key) {
    //       this.forHelpResonIndex = key;
    //       this.forHelpReson = item;
    //     }
    //   });
    // },
    // 求助管理员表单提交
    onSubmitForHelpAdmin() {
      this.btn_loading = true;
      // if (this.forHelpReson === "") {
      //   this.$toast("请选择求助方式");
      // } else {
      //   if (this.forHelpResonIndex === this.forHelpResonList.length - 1) {
      //     this.forHelpReson = this.forHelpResons;
      //   } else {
      //     this.forHelpReson === this.forHelpReson;
      //   }
      //   let type =
      //     this.forHelpResonIndex === 0
      //       ? 1
      //       : this.forHelpResonIndex === 1
      //       ? 2
      //       : "";
      //   if (this.forHelpReson === null || this.forHelpReson === "") {
      //     this.$toast("请选择求助方式");
      //   } else {
      updateCustomer({
        id: this.customerId,
        type: 2,
        desc: this.forHelpResons,
      })
        .then((res) => {
          if (res.code === 200) {
            this.$toast.success(res.data);
            this.btn_loading = false;
            this.showforHelpAdmin = false;
            this.page = 1;
            this.loading = false;
            this.finished = false;
            this.myFollowList = [];
            this.getMyFollowList(this.tabType);
          }
        })
        .catch(() => {
          this.btn_loading = false;
        });
      // }
      // }
    },
    // 关闭客户参观记录弹窗清空表单数据
    closeAttendRecord() {
      this.attendRecordForm = this.$options.data().attendRecordForm;
    },
    // 跟进人选择
    onPersonConfirm(value) {
      this.attendRecordForm.pepole = value;
      this.followPersonList.map((item) => {
        if (this.attendRecordForm.pepole === item.name) {
          this.followUpId = item.id;
        }
      });
      this.showPerson = false;
    },
    // 预约参观时间选择
    onConfirmVisitDatePicker(time) {
      this.attendRecordForm.visitDate = moment(time).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.showVisitDatePicker = false;
    },
    // 客户参观记录表单提交
    onSubmitAttendRecord() {
      this.btn_loading = true;
      ordervisitlogs({
        id: this.followUpId,
        orderid: this.customerId,
        time: this.attendRecordForm.visitDate,
      })
        .then((res) => {
          if (res.code === 200) {
            this.$toast.success(res.data);
            this.btn_loading = false;
            this.showAttendRecord = false;
            this.page = 1;
            this.loading = false;
            this.finished = false;
            this.myFollowList = [];
            this.getMyFollowList(this.tabType);
          }
        })
        .catch(() => {
          this.btn_loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.myFollow {
  margin-top: 26px;
  .van-tabs {
    margin-top: 10px;
  }
  p {
    font-size: 14px;
    text-align: center;
    margin: 20px 0;
    color: #9a9a9a;
  }
  /deep/.van-tab--active {
    color: #f3973b;
    border-left: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
  }
  /deep/.van-tabs__line {
    width: 50%;
    height: 4px;
    background-color: #f3973b;
  }
  .van-row:nth-child(1) {
    min-width: 100%;
    min-height: 115px;
    background-color: #fff;
    margin-bottom: 5px;
    font-size: 13px;
    .van-col:nth-child(1) {
      color: #9a9a9a;
      font-size: 13px;
      div {
        font-size: 13px;
      }
      span {
        font-size: 13px;
      }
    }
    .client-right {
      float: right;
      margin-right: 16px;
    }
    .van-divider {
      border-color: #eaeaea;
      padding: 0 16px;
    }
    .van-col:last-child {
      padding-bottom: 15px;
    }
    .van-col {
      font-size: 13px;
      color: #9a9a9a;
      padding-bottom: 10px;
      padding-left: 16px;
      span {
        color: #000;
        font-size: 13px;
      }
    }
    .van-col:nth-child(4) {
      padding-left: 0;
    }
  }
  #count-text {
    color: #f3973b;
  }
  .client-botton-item {
    min-height: 168px;
    background-color: #fff;
    padding-bottom: 16px;
    margin-bottom: 10px;
    .van-col {
      text-align: center;
    }
    .van-col:nth-child(1) {
      font-size: 13px;
      display: flex;
      color: #f3973b;
      flex-direction: inherit;
      justify-content: center;
      margin: 23px 0 18px 0;
      img {
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }
    }
    .client-botton {
      width: 158px;
      background-color: #f2f2f2;
      color: #343434;
      border: #343434;
      font-size: 12px;
      margin-bottom: 10px;
    }
  }
  .van-popup--center {
    border-radius: 10px;
    top: 50%;
    left: 5%;
    right: 5%;
    transform: translate3d(0, -50%, 0);
  }
  ::v-deep .van-field--disabled .van-field__label {
    color: #646566;
  }
  // 弹框
  .van-popup {
    background: #fff;
    .follow_header {
      width: 100%;
      min-height: 45px;
      line-height: 45px;
      border-bottom: 1px solid #eaeaea;
      display: flex;
      justify-content: flex-start;
      flex-direction: inherit;
      #followRecord {
        height: 345px;
        position: fixed;
        top: 0;
        background: #fff;
      }
      div {
        width: 4px;
        height: 20px;
        background: #f3983c;
        margin: 12px 8px 10px 12px;
      }
      span {
        font-size: 18px;
        color: #343434;
      }
    }
    .van-form {
      max-height: 405px;
      overflow: auto;
      .customer-info {
        display: flex;
        flex-direction: initial;
        justify-content: flex-start;
        color: #9a9a9a;
        span {
          font-size: 16px;
          padding: 16px 0 6px 21px;
        }
        span:nth-child(2) {
          color: #000;
          padding-left: 30px;
        }
      }
      .van-field {
        margin-top: 10px;
      }
      .stayin-reson-botton {
        background-color: #fff;
        border: 1px solid #eaeaea;
        color: #9a9a9a;
        margin: 8px;
      }
      .stayin-reson-active {
        background-color: #fff;
        border: 1px solid #f3973b;
        color: #f3973b;
        margin: 8px;
      }
      /deep/.van-button--info,
      /deep/.van-button--primary {
        background-color: #f3973b;
        border: #f3973b;
      }
      .oneself-class {
        .van-button {
          color: #999999;
        }
      }
      .oneself-class-active {
        .van-button {
          color: #f3973b;
          background: #fef5eb;
        }
      }
    }
    .follow-record-list {
      max-height: 405px;
      overflow: auto;
      .follow-record-item {
        padding: 16px 16px 0 16px;
        .follow-record-count {
          width: 34px;
          height: 20px;
          line-height: 24px;
          border: 1px solid #f3983c;
          border-radius: 20px;
          text-align: center;
          color: #f3983c;
          font-size: 11px;
        }
        .van-row {
          margin-top: 10px;
          .van-col {
            color: #9a9a9a;
            font-size: 12px;
            line-height: 26px;
            span {
              color: #343434;
              font-size: 12px;
            }
          }
        }
      }
      .van-button {
        background-color: #f3973b;
        border: #f3973b;
      }
      .van-divider {
        margin-bottom: 0;
      }
    }
  }
  .van-overlay {
    z-index: 999;
  }
}
</style>
